<script>
import Layout from "../../../layouts/main";

import PageHeader from "@/components/page-header";

import store from "@/state/store";
import {apiVersionMethods} from "@/state/helpers";
import {helpers, required} from "@vuelidate/validators";
import Swal from 'sweetalert2'
import useVuelidate from "@vuelidate/core";

/**
 * Editors component
 */
  
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      menuId: 500200,
      menuCode: "version",

      android:"",
      ios:"",
      formSubmitted: false,
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    this.loadData()
  },
  validations: function() {
    return {
      android: {
          required: helpers.withMessage("Android 버전을 입력해 주세요.", required),
      },
      ios: {
          required: helpers.withMessage("IOS 버전을 입력해 주세요.", required)
      },
    }
  },
  methods: {
    ...apiVersionMethods,

    // 데이터 로딩
    loadData() {
        store.dispatch("apiVersion/load").then((result) => {
            let data = result.data
            
            this.android = data.android
            this.ios = data.ios
        })
    },


    // 안드로이드 버전저장
    saveAndroid() {
      store.dispatch('notification/clear')

      this.formSubmitted = true
      let params = {
        "android" : this.android
      }
      this.saveData("/version/android", params)
    },

    //아이폰 버전 저장
    saveIOS() {
      store.dispatch('notification/clear')

      this.formSubmitted = true
      let params = {
        "ios" : this.ios
      }
      this.saveData("/version/ios", params)
    },

    saveData(url, params){
      store.dispatch("apiVersion/store", { url, params }).then((result) => {
        this.formSubmitted = false
        Swal.fire(result.message, "", result.data.success ? "success" : "error");
      }, () => {
        this.formSubmitted = false
      });
    }
  },
  computed: {
  }
};
</script>

<template>
  <Layout>
    <PageHeader/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">앱 버전 관리 안내</div>
            <div>
              <ul class="list-group">
                  <li class="list-group-item">1. 해당 내용은 수정즉시 반영됩니다.</li>
                  <li class="list-group-item">2. 안드로이드는 버전 수정 후 매 자정에 해당 값을 가지고 업데이트 푸쉬를 출력합니다.</li>
                  <li class="list-group-item">3. 안드로이드와 아이폰 각각 수정을 해야합니다.</li>
                  <li class="list-group-item">4. 버전수정은 하위 번호로 변경 할 수 없습니다.</li>
                  <li class="list-group-item">5. 적용시 앱 배포가 완료간 된 후 해당 앱 버전으로 맞춰서 입력, 수정해야합니다.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <b-form  @submit.prevent="saveAndroid" enctype="multipart/form-data">
                <b-form-group
                    class="mb-3"
                    label="Android Version"
                    label-for="android"
                >
                    <b-form-input
                    type="text"
                    v-model="android"
                    :disabled="formSubmitted"
                    ></b-form-input>
                </b-form-group>
                <div class="text-start">
                    <b-button variant="primary" type="submit" :disabled="formSubmitted">
                      <span>안드로이드 버전 수정</span>
                    </b-button>
                </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <b-form  @submit.prevent="saveIOS" enctype="multipart/form-data">
                <b-form-group
                    class="mb-3"
                    label="IOS Version"
                    label-for="ios"
                >
                    <b-form-input
                    type="text"
                    v-model="ios"
                    :disabled="formSubmitted"
                    ></b-form-input>
                </b-form-group>
                <div class="text-start">
                    <b-button variant="primary" type="submit" :disabled="formSubmitted">
                      <span>아이폰 버전 수정</span>
                    </b-button>
                </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
